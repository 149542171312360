<template>
  <div>
    <h1>{{ groupName }}</h1>
    <b-row cols-md="2" cols="1">
      <b-col md="5" sm="6">
        <WorkoutCalendar
          :workoutDates="workoutDates"
          :disabled="isWorkoutBeingEdited"
          @selected="setSelectedDate"
          />
        <WorkoutForm :currentGroup="currentGroup" :state="workoutFormState" class="my-3"/>
      </b-col>
      <b-col md="7" sm="6">
        <GroupMembers :currentGroupId="currentGroupId"/>
        <GroupInstructors :currentGroupId="currentGroupId" class="mt-3"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex';
  import { todaysDate } from '@/utils/date.js'
  import WorkoutCalendar from '@/components/WorkoutCalendar.vue'
  import GroupMembers from '@/components/GroupMembers.vue'
  import GroupInstructors from '@/components/GroupInstructors.vue'
  import WorkoutForm from '@/components/WorkoutForm.vue'

  export default {
    created() {
      this.bindWorkouts().then(() => {
        console.log('Workouts bound');
      }).catch((error) => {
        console.error('Failed to bind workouts:', error);
      });
      this.bindGroupMembers().then(() => {
        console.log('Group members bound');
      }).catch((error) => {
        console.error('Failed to bind group members:', error);
      });
      this.setSelectedDate(todaysDate())
      this.bindPrePromotions().then(() => {
        console.log('PrePromotions bound');
      }).catch((error) => {
        console.error('Failed to bind pre-promotions:', error);
      });
    },
    destroyed() {
      this.setCurrentWorkout(null);
      this.unbindWorkouts().then(() => {
        console.log('Workouts unbound');
      }).catch((error) => {
        console.error('Failed to unbind workouts:', error);
      });
      this.unbindGroupMembers().then(() => {
        console.log('Group members unbound');
      }).catch((error) => {
        console.error('Failed to unbind group members:', error);
      });
      this.unbindPrePromotions().then(() => {
        console.log('Pre-promotions unbound');
      }).catch((error) => {
        console.error('Failed to unbind pre-promotions:', error);
      });
    },
    components: { WorkoutForm, GroupInstructors, GroupMembers, WorkoutCalendar},
    computed: {
      groupName: function() {
        if (this.currentGroup) {
          return this.currentGroup.name;
        } else {
          return '';
        }
      },
      workoutFormState: function() {
        if (this.isWorkoutBeingEdited) {
          return 'editing'
        }
        if (this.workoutDates.includes(this.selectedDate)) {
          return 'allowEdit'
        }
        return 'allowCreate'
      },
      currentGroupId() {
        return this.$route.params.id
      },
      ...mapState('attendance', ['workouts', 'currentWorkout', 'editedWorkout', 'selectedDate']),
      ...mapGetters('attendance', ['currentGroup', 'isWorkoutBeingEdited', 'workoutDates'])
    },
    methods: {
      ...mapActions('attendance', ['bindWorkouts', 'unbindWorkouts', 'bindGroupMembers', 'unbindGroupMembers', 'bindPrePromotions', 'unbindPrePromotions', 'setSelectedDate', 'setCurrentWorkout'])
    }
  };
</script>
