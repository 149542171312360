<template>
  <b-card no-body >
    <b-card-header class="d-flex justify-content-between align-items-center">
      <div>Elever <span v-if="currentWorkout">{{ prettyDate }}</span></div>
      <b-button-toolbar>
        <b-button size="sm" class="mx-1" @click="toggleSortOrder">{{ sortOrder === 'attendance' ? '↓A' : '↓%' }}</b-button>
        <b-button v-b-modal.add-person-modal size="sm" class="mx-1" variant="primary">
          <font-awesome-icon :icon="['fas', 'user-plus']" ></font-awesome-icon>
        </b-button>
      </b-button-toolbar>
    </b-card-header>
    <b-list-group v-if="members.length" flush>
      <b-list-group-item
        v-for="member in members"
        :key="member.id"
        @click="onClick(member)"
	:disabled="disabled(member)"
	:variant="variant(member)"
	class="d-flex justify-content-between align-items-center"
        >
        <span>
          <GroupMemberIcon :isWorkout="isWorkoutSelected" :isAttending="isAttending(member)"/>
          {{ member.name }}
        </span>
	<div>
          <font-awesome-icon v-if="approvedForPromotion(member)" :icon="['fas', 'arrow-up']"></font-awesome-icon>
          <font-awesome-icon v-if="notApprovedForPromotion(member)" :icon="['fas', 'arrow-rotate-right']"></font-awesome-icon>
          <span class="m-3">{{ numberOfWorkoutsAttended(member) }}</span>
          <FeeImage :trial="member.isProspect" :membership="membershipPaid(member)" :workout="workoutPaid(member)"/>
	</div>
      </b-list-group-item>
    </b-list-group>
    <b-card-body v-else>Klicka på knappen ovan för att lägga till medlemmar i gruppen. Välj sedan start och längd och klicka på "Nytt pass" för att registrera närvaro.</b-card-body>
    <b-card-footer v-if="members.length">
      <span v-if="editedWorkout">{{editedWorkout.attendees.length}} av </span>
      <span v-else-if="currentWorkout">{{currentWorkout.attendees.length}} av </span>
      {{ members.length }} person<span v-if="members.length != 1">er</span>
    </b-card-footer>
    <SelectPersonModal id="add-person-modal" @person="addGroupMember" :disable-ids="members.map(m => m.isProspect ? m.prospectId : m.memberId)" title="Lägg till elev"/>
  </b-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import FeeImage from '@/components/FeeImage.vue'
import GroupMemberIcon from '@/components/GroupMemberIcon.vue'
import SelectPersonModal from '@/components/SelectPersonModal.vue'

export default {
  components: { FeeImage, GroupMemberIcon, SelectPersonModal },
  props: {
    currentGroupId: String
  },
  computed: {
    ...mapState('attendance', ['groupMembers', 'editedWorkout', 'workouts', 'prePromotions']),
    ...mapGetters('attendance', ['currentWorkout']),
    members() {
      return this.groupMembers
        .filter(gm => !gm.isInstructor)
        .sort((member1, member2) => { return this.sortOrder === 'attendance' ? this.numberOfWorkoutsAttended(member2) - this.numberOfWorkoutsAttended(member1) : member1.name.localeCompare(member2.name, 'sv') })
    },
    prettyDate() {
      if (this.currentWorkout && this.currentWorkout.date) {
        return new Date(this.currentWorkout.date)
          .toLocaleDateString("sv-SE", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
      } else {
        return ''
      }
    },
    isWorkoutSelected() {
      return !!(this.currentWorkout || this.editedWorkout)
    }
  },
  data() {
    return {
      sortOrder: 'attendance',
      currentPeriod: 'ht-2024'
    }
  },
  methods: {
    ...mapActions('attendance', ['addMemberToGroup']),
    onClick(member) {
      if (this.editedWorkout) {
        this.toggleAttendance(member)
      }
    },
    toggleAttendance(member) {
      let attendees = this.editedWorkout.attendees
      if (attendees.includes(member.id)) {
        var index = attendees.indexOf(member.id)
        attendees.splice(index, 1)
      } else {
        attendees.push(member.id)
      }
    },
    toggleSortOrder() {
      this.sortOrder = this.sortOrder === 'attendance' ? 'name' : 'attendance'
    },
    isAttending(member) {
      if (this.isWorkoutSelected) {
        if (this.editedWorkout) {
          return this.editedWorkout.attendees.includes(member.id)
        } else {
          return this.currentWorkout.attendees.includes(member.id)
        }
      }
      return false
    },
    variant(member) {
      return this.isAttending(member) ? 'success' : ''
    },
    addGroupMember(person) {
      let groupMember = {
        groupId: this.currentGroupId,
        name: person.firstName + ' ' + person.lastName,
        isProspect: person.isProspect
      }
      if (person.isProspect) {
        groupMember.prospectId = person.id
      } else {
        groupMember.memberId = person.id
      }
      this.addMemberToGroup(groupMember)
    },
    membershipPaid(member) {
      return member.fees && member.fees.filter(fee => fee.year == '2024').map(fee => fee.type).includes('membership')
    },
    workoutPaid(member) {
      return member.fees && !!member.fees.filter(fee => fee.period === this.currentPeriod).find(fee => fee.type.startsWith('workout'))
    },
    numberOfWorkoutsAttended(member) {
      return this.workouts.filter(w => w.attendees.includes(member.id)).length
    },
    disabled(member) {
      return !this.editedWorkout || this.isPresentAsInstructor(member)
    },
    isPresentAsInstructor(member) {
      const presentInstructors = this.groupMembers.filter(gm => this.editedWorkout.instructors.includes(gm.id))
      const foundMatchingMemberId = member.memberId && presentInstructors.map(i => i.memberId).includes(member.memberId)
      const foundMatchingProspectId = member.prospectId && presentInstructors.map(i => i.prospectId).includes(member.prospectId)
      return foundMatchingMemberId || foundMatchingProspectId
    },
    prePromotionStatus(member) {
      let status = null
      this.prePromotions.some(promotion => {
        if (promotion.approved.some(p => p.memberId === member.memberId)) {
          status = 'approved'
          return true
        } else if (promotion.notApproved.some(p => p.memberId === member.memberId)) {
          status = 'notApproved'
          return true
        } else {
          return false
        }
      })
      return status
    },
    approvedForPromotion(member) {
      return this.prePromotionStatus(member) == 'approved'
    },
    notApprovedForPromotion(member) {
      return this.prePromotionStatus(member) == 'notApproved'
    }
  }
}
</script>
