<!-- group-member-icon.vue
  If isWorkout is true, display a checkbox indicating attendance,
  else display a user-ninja icon for instructors and user icon for others.
-->

<template>
  <font-awesome-icon :icon="icon"></font-awesome-icon>
</template>

<script>
export default {
  props: {
    isInstructor: {
      type: Boolean,
      default: false
    },
    isWorkout: {
      type: Boolean,
      default: false
    },
    isAttending: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    icon() {
      if (this.isWorkout) {
        if (this.isAttending) {
          return ['fas', 'check-square']
        }
        return ['fas', 'square']
      }
      if (this.isInstructor) {
        return ['fas', 'user-ninja']
      }
      return ['fas', 'user']
    }
  }
}
</script>
