<template>
    <div>
      <b-form v-if="canCreate" inline>
        <b-form-select
          v-model="selectedStartTime"
          :options="suggestedStartTimes"
          class="mr-1"
          size="sm">
          <template #first>
            <b-form-select-option :value="null" disabled>Start:</b-form-select-option>
          </template>
	</b-form-select>
        <b-form-select
          v-model="selectedDuration"
          :options="suggestedDurations"
          class="mr-1"
          size="sm">
          <template #first>
            <b-form-select-option :value="null" disabled>Längd:</b-form-select-option>
          </template>
        </b-form-select>
        <b-button
          id="workout-create-button"
          @click="createNewWorkout"
          variant="primary"
          :disabled="!(selectedStartTime && selectedDuration)"
          size="sm"
          >Nytt pass</b-button>
      </b-form>
      <b-form v-if="canEdit" inline>
        <b-button
          variant="outline-secondary"
          :disabled="true"
          class="mr-1"
          size="sm">
          <font-awesome-icon :icon="['far', 'clock']" ></font-awesome-icon>
          {{ currentWorkout.startTime }}
        </b-button>
        <b-button
          v-model="currentWorkout.duration"
          variant="outline-secondary"
          :disabled="true"
          class="mr-1"
          size="sm">
          <font-awesome-icon :icon="['fas', 'hourglass-half']" ></font-awesome-icon>
          {{ currentWorkout.duration }} min
        </b-button>
        <b-button
          id="edit-button"
          @click="editWorkout"
          variant="primary"
          size="sm"
          >Ändra</b-button>
      </b-form>
      <b-form v-if="editing" inline>
        <b-form-select
          v-model="editedWorkout.startTime"
          :options="suggestedStartTimes"
          class="mr-1"
          size="sm">
	</b-form-select>
        <b-form-select
          v-model="editedWorkout.duration"
          :options="suggestedDurations"
          class="mr-1"
          size="sm">
        </b-form-select>
        <b-button
          id="discard-button"
          @click="discardChanges"
          class="mr-1"
          size="sm">
          Avbryt</b-button>
        <b-button
          id="save-button"
          @click="saveEditedWorkout"
          variant="primary"
          :disabled="!canSave()"
          size="sm"
          >Spara</b-button>
      </b-form>
    </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';

  export default {
    data() {
      return {
        selectedStartTime: null,
        selectedDuration: null,
        suggestedStartTimes: ['13:00', '15:00', '17:30', '18:00', '18:15', '18:30', '18:45', '19:15', '20:15', '20:30'],
        suggestedDurations: ['60', '75', '90']
      }
    },
    computed: {
      ...mapState('attendance', ['editedWorkout', 'selectedDate']),
      ...mapGetters('attendance', ['currentGroupId', 'currentWorkout']),
      canCreate() {
        return this.state === 'allowCreate'
      },
      canEdit() {
        return this.state === 'allowEdit'
      },
      editing() {
        return this.state === 'editing'
      }
    },
    props: {
      currentGroup: Object,
      state: String  // states: allowCreate, allowEdit, editing
    },
    methods: {
      ...mapActions('attendance', ['saveEditedWorkout', 'setEditedWorkout']),
      createNewWorkout() {
        const newWorkout = {
          groupId: this.currentGroupId,
          date: this.selectedDate,
          startTime: this.selectedStartTime,
          duration: this.selectedDuration,
          attendees: [],
          instructors: [],
        }
        this.setEditedWorkout(newWorkout)
      },
      editWorkout() {
        const editedWorkout = {
          groupId: this.currentWorkout.groupId,
          date: this.currentWorkout.date,
          startTime: this.currentWorkout.startTime,
          duration: this.currentWorkout.duration,
          attendees: this.currentWorkout.attendees.slice(),
          instructors: this.currentWorkout.instructors.slice(),
          id: this.currentWorkout.id
        }
        this.setEditedWorkout(editedWorkout)
      },
      discardChanges() {
        this.setEditedWorkout(null)
      },
      canSave() {
        return this.editedWorkout && (this.editedWorkout.attendees.length || this.editedWorkout.instructors.length)
               || this.editedWorkout && this.currentWorkout
      },
      setScheduleFor(dayOfWeek) {
        if (this.currentGroup.schedule) {
          let schedule = this.currentGroup.schedule.find(entry => entry.weekday === dayOfWeek)
          if (schedule) {
            this.selectedStartTime = schedule.startTime
            this.selectedDuration = schedule.duration
          } else {
            this.selectedStartTime = null
            this.selectedDuration = null
          }
        }
      }
    },
    created() {
      this.setScheduleFor(new Date().getDay())
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === 'attendance/SET_SELECTED_DATE') {
          let selectedDate = new Date(mutation.payload)
          this.setScheduleFor(selectedDate.getDay())
        }
      })
    },
    beforeDestroy() {
      this.unsubscribe()
    }
  }
</script>
