<template>
  <svg height="25" viewBox="0 0 10 20" xmlns="http://www.w3.org/2000/svg">
    <circle cx="5" cy="5" r="4" :stroke="strokeColor('M')" :fill="fillColor('M')"/>
    <circle cx="5" cy="15" r="4" :stroke="strokeColor('W')" :fill="fillColor('W')"/>
  </svg>
</template>

<script>
export default {
  props: {
    trial: Boolean,
    membership: Boolean,
    workout: Boolean
  },
  methods: {
    strokeColor(type) {
      if (this.trial) {
        return 'gray'
      }
      if (type == 'M') {
        return this.membership ? 'green' : 'maroon'
      } else if (type == 'W') {
        return this.workout ? 'green' : 'maroon'
      } else {
        return 'gray'
      }
    },
    fillColor(type) {
      if (this.trial) {
        return 'yellow'
      }
      if (type == 'M') {
        return this.membership ? 'lightgreen' : 'red'
      } else if (type == 'W') {
        return this.workout ? 'lightgreen' : 'red'
      } else {
        return 'yellow'
      }
    }
  }
}
</script>
