<template>
  <b-calendar
    v-model="selectedDate"
    locale="sv-SE"
    start-weekday="1"
    :hide-header="true"
    :hide-help="true"
    :date-info-fn="dateClass"
    :min="min"
    :max="max"
    :disabled="disabled"
    v-bind="labels || {}"
    @selected="selected">
  </b-calendar>
</template>

<script>
  export default {
    props: {
      now: {
        type: Date,
        default: () => { return new Date() }
      },
      disabled: {
        type: Boolean,
        default: false
      },
      workoutDates: {
        type: Array,
        default: () => { return [] }
      }
    },
    data() {
      const today = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate())
      const minDate = new Date(today)
      minDate.setMonth(minDate.getMonth() - 6)
      return {
        labels: {
          labelPrevDecade: 'Föregående årtionde',
          labelPrevYear: 'Föregående år',
          labelPrevMonth: 'Föregående månad',
          labelCurrentMonth: 'Denna månad',
          labelNextMonth: 'Nästa månad',
          labelNextYear: 'Nästa år',
          labelNextDecade: 'Nästa årtionde',
          labelToday: 'Idag',
          labelSelected: 'Valt datum',
          labelNoDateSelected: 'Inget datum är valt',
          labelCalendar: 'Kalender',
          labelNav: 'Kalendernavigation',
          labelHelp: ''
        },
        min: minDate,
        max: today,
        selectedDate: today.toLocaleDateString('sv-SE')
      }
    },
    methods: {
      selected(date) {
        this.$emit('selected', date)
      },
      dateClass(ymd) {
        if (this.workoutDates.includes(ymd)) {
          return ['badge', 'badge-pill', 'badge-success']
        }
      }
    }
  }
</script>
