
/* Return todays date as a string on the format '2021-08-19' */
const todaysDate = () => {
  const today = new Date()
  return today.toISOString().slice(0, 10)
}

/* Return the number of years between two dates, as an integer */
const yearsBetweenDates = (date1, date2) => {
  const firstDate = date1 < date2 ? date1 : date2
  const secondDate = date1 > date2 ? date1 : date2
  let years = secondDate.getFullYear() - firstDate.getFullYear()
  const m = secondDate.getMonth() - firstDate.getMonth()
  if (m < 0 || (m === 0 && secondDate.getDate() < firstDate.getDate())) {
    years--
  }
  return years
}

export {
  todaysDate, yearsBetweenDates
}
