<template>
  <b-card no-body>
    <b-card-header class="d-flex justify-content-between align-items-center">
      <div>Instruktörer</div>
      <b-button v-b-modal.add-instructor-modal size="sm" variant="primary">
        <font-awesome-icon :icon="['fas', 'user-ninja']" ></font-awesome-icon>+
      </b-button>
    </b-card-header>
    <b-list-group v-if="instructors.length" flush>
      <b-list-group-item
        v-for="member in instructors"
        :key="member.id"
        @click="onClick(member)"
	:disabled="disabled(member)"
	:variant="variant(member)"
	class="d-flex justify-content-between align-items-center"
        >
        <span>
          <GroupMemberIcon :isInstructor="true" :isWorkout="isWorkoutSelected" :isAttending="isAttending(member)"/>
          {{ member.name }}
        </span>
	<div>
          <span class="m-3">{{ numberOfWorkoutsAttended(member) }}</span>
          <FeeImage :trial="member.isProspect" :membership="membershipPaid(member)" :workout="workoutPaid(member)"/>
	</div>
      </b-list-group-item>
    </b-list-group>
    <b-card-body v-else>Klicka på knappen ovan för att lägga till instruktör(er) i gruppen. Välj sedan start och längd och klicka på "Nytt pass" för att registrera närvaro.</b-card-body>
    <SelectPersonModal id="add-instructor-modal" @person="addGroupMember" :disable-ids="instructors.map(m => m.isProspect ? m.prospectId : m.memberId)" title="Lägg till instruktör" />
  </b-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import FeeImage from '@/components/FeeImage.vue'
import GroupMemberIcon from '@/components/GroupMemberIcon.vue'
import SelectPersonModal from '@/components/SelectPersonModal.vue'

export default {
  components: { FeeImage, GroupMemberIcon, SelectPersonModal },
  props: {
    currentGroupId: String
  },
  computed: {
    ...mapState('attendance', ['groupMembers', 'editedWorkout', 'workouts']),
    ...mapGetters('attendance', ['currentWorkout']),
    instructors() {
      return this.groupMembers
        .filter(gm => gm.isInstructor)
        .sort((member1, member2) => this.numberOfWorkoutsAttended(member2) - this.numberOfWorkoutsAttended(member1))
    },
    isWorkoutSelected() {
      return !!(this.currentWorkout || this.editedWorkout)
    }
  },
  methods: {
    ...mapActions('attendance', ['addInstructorToGroup']),
    onClick(member) {
      if (this.editedWorkout) {
        this.toggleAttendance(member)
      }
    },
    toggleAttendance(member) {
      let instructors = this.editedWorkout.instructors
      if (instructors.includes(member.id)) {
        console.log('Removing attendance for', member.name)
        var index = instructors.indexOf(member.id)
        instructors.splice(index, 1)
      } else {
        console.log('Adding attendance for', member.name)
        instructors.push(member.id)
      }
    },
    isAttending(member) {
      if (this.isWorkoutSelected) {
        if (this.editedWorkout) {
          return this.editedWorkout.instructors.includes(member.id)
        } else {
          return this.currentWorkout.instructors.includes(member.id)
        }
      }
      return false
    },
    variant(member) {
      return this.isAttending(member) ? 'success' : ''
    },
    addGroupMember(person) {
      let groupMember = {
        groupId: this.currentGroupId,
        name: person.firstName + ' ' + person.lastName,
        isProspect: person.isProspect,
        isInstructor: true
      }
      if (person.isProspect) {
        groupMember.prospectId = person.id
      } else {
        groupMember.memberId = person.id
      }
      this.addInstructorToGroup(groupMember)
    },
    membershipPaid(member) {
      return member.fees && member.fees.filter(fee => fee.year == '2024').map(fee => fee.type).includes('membership')
    },
    workoutPaid(member) {
      return member.fees && !!member.fees.filter(fee => fee.period === 'ht-2024').find(fee => fee.type.startsWith('workout'))
    },
    numberOfWorkoutsAttended(member) {
      return this.workouts.filter(w => w.instructors.includes(member.id)).length
    },
    disabled(member) {
      return !this.editedWorkout || this.isPresentAsAttendee(member)
    },
    isPresentAsAttendee(member) {
      const presentAttendees = this.groupMembers.filter(gm => this.editedWorkout.attendees.includes(gm.id))
      const foundMatchingMemberId = member.memberId && presentAttendees.map(i => i.memberId).includes(member.memberId)
      const foundMatchingProspectId = member.prospectId && presentAttendees.map(i => i.prospectId).includes(member.prospectId)
      return foundMatchingMemberId || foundMatchingProspectId
    }
  }
}
</script>
